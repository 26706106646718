import classNames from "clsx"
import styles from "./FamilyNode.module.css"

export default function FamilyNode({ node, isRoot, style }) {
  return (
    <div
      className={`${styles.root} ${node.holucost === 0 ? "hide" : ""}`}
      style={style}
    >
      <div
        className={classNames(
          styles[node.DeathType],
          styles.inner,
          styles[node.gender],
          isRoot && styles.isRoot
        )}
      >
        <div className={styles.content}>
          <h4 className={styles.name}>{node.relation} </h4>
          <div className={styles.dates}>
            <div>Name: {node.name}</div>
            <div>Associated: {node.associated}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
